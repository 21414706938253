<template>
  <div ref="constructor_car_canvas" class="car-canvas">
    <fabric-canvas
      :height="532"
      :width="1064"
      :enable-retina-scaling="false"
      class="canvas-container"
      :class="{ invisible: loading }"
      :selection="false"
      @canvas-updated="onCanvasUpdated"
      @object-added="onObjAdded"
      @object-removed="onObjRemoved"
      @object-modified="onObjModified"
      @selection-created="onSelected"
      @selection-updated="onSelected"
      @selection-cleared="onUnselected"
      @mouse-move="onMouseMove"
      @mouse-over="onMouseOver"
      @mouse-out="onMouseOut"
      @mouse-up="onMouseUp"
    >
      <component
        :is="o.type"
        v-for="o in fObjs"
        :id="o.id"
        :key="o.id"
        v-bind="o.props"
        @url-loading="numUrlToLoad++"
        @url-loaded="numUrlToLoad--"
      >
        <component
          :is="c.type"
          v-for="c in o.children"
          :id="c.id"
          :key="c.id"
          v-bind="c.props"
        />
      </component>
    </fabric-canvas>
    <div
      v-if="shownPipette"
      class="pipette"
      :style="`left: ${coordsPipette.x}px; top: ${coordsPipette.y}px; background: ${colorPipette};`"
    ></div>
    <preloader-double-bounce v-if="loading" class="preloader" />
  </div>
</template>

<script>
const clone = require("rfdc")();

import { mapFields, createHelpers } from "vuex-map-fields";

import "@/components/vue-fabric-wrapper";

import PreloaderDoubleBounce from "@/components/PreloaderDoubleBounce";

import { rgbaToHexa } from "@/components/vue-color-gradient-picker/helpers";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

const propsToModify = [
  "left",
  "top",
  "width",
  "height",
  "scaleX",
  "scaleY",
  "angle",
  "text",
];

export let canvas = null;

export default {
  components: {
    PreloaderDoubleBounce,
  },
  data() {
    return {
      canvas: null,
      context2d: null,
      numUrlToLoad: null,
      shownPipette: false,
      coordsPipette: {},
      colorPipette: "",
    };
  },
  computed: {
    ...mapFields([
      "tempCars",
      "viewId",
      "carIndxDesignApplied",
      "isFObjAdded",
      "isFigsEditable",
      "fObjSelectedId",
      "toolbar",
      "bgGradientPointIndx",
    ]),
    ...mapCarFields({
      vcq: "vcq",
      carViews: "views",
    }),

    loading() {
      return this.numUrlToLoad === null || this.numUrlToLoad > 0;
    },
    fObjs() {
      if (!this.carViews || !this.carViews[this.viewId]) return null;
      const view = clone(this.carViews[this.viewId]);

      const fObjsIndxsToDel = []; // to delete objects w/ empty url, path

      // merge backgrounds
      const bgsIndx = view.fObjs.findIndex((o) => o === "{{backgrounds}}");
      view.fObjs.splice(bgsIndx, 1, ...view.backgrounds);

      // merge figures
      const figsIndx = view.fObjs.findIndex((o) => o === "{{figures}}");
      view.fObjs.splice(figsIndx, 1, ...view.figures);

      // resolve template variables
      view.fObjs.forEach((o, i) => {
        Object.entries(o.props || {}).forEach((kv) => {
          if (kv[1] === "{{color}}") {
            o.props[kv[0]] = "#" + this.vcq.color;
          }
          if (typeof kv[1] === "object" && kv[1] !== null && o.switcher) {
            if (o.switcher === "{{part}}") {
              const val = kv[1][view.part];
              if (!val) {
                fObjsIndxsToDel.push(i);
              } else {
                o.props[kv[0]] = val;
              }
            }
          }
        });
      });
      return view.fObjs.filter((_, i) => !fObjsIndxsToDel.includes(i));
    },
    fObjIndx() {
      return this.fObjs?.reduce((a, c, i) => ((a[c.id] = i), a), {});
    },
  },
  watch: {
    async loading(val) {
      if (val) return;
      this.onResize();
      this.sortCarViewFObjs();
      await new Promise((_) => setTimeout(_, 200));
      this.$emit("ready");
    },
    async fObjs() {
      await this.$nextTick();
      this.sortCarViewFObjs();
    },
    // if views from API is not ready on created
    tempCars: "setCarViews",
    carViews: "setCarViews",
    toolbar: {
      handler(val) {
        if (
          !this.carViews ||
          !this.carViews[this.viewId] ||
          !this.fObjSelectedId
        )
          return null;
        const view = this.carViews[this.viewId];
        const fObj = view.figures.find((f) => f.id === this.fObjSelectedId);
        const type = this.fObjSelectedId.split("-").shift();
        switch (type) {
          case "fig":
            fObj.props = { ...fObj.props, ...val.figure };
            break;
          case "sticker":
            fObj.props = { ...fObj.props, ...val.sticker };
            break;
          case "text":
            fObj.props = { ...fObj.props, ...val.text };
            break;
        }
        this.carViews = { ...this.carViews }; // to update in Vuex
      },
      deep: true,
    },
    async fObjSelectedId(val, prev) {
      if (!val || val === prev) return;

      let fObj;
      do {
        await new Promise((_) => setTimeout(_, 100));
        fObj = canvas.getObjects().find((o) => o.id === val);
      } while (!fObj);

      if (!fObj) return;
      canvas.setActiveObject(fObj);
    },
    isFigsEditable(val) {
      const view = this.carViews[this.viewId];

      view.figures.forEach(
        (f) => ((f.props.selectable = val), (f.props.evented = val))
      );

      const bg = view.backgrounds[0];
      if (bg?.props?.id === "bg-image") {
        bg.props.selectable = !val;
        bg.props.evented = !val;
      }

      this.carViews = { ...this.carViews }; // to update in Vuex
    },
    bgGradientPointIndx(val) {
      this.shownPipette = val !== null;
    },
  },
  created() {
    this.setCarViews();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const outerCanvasContainer = this.$refs["constructor_car_canvas"];
      const rect = outerCanvasContainer.getBoundingClientRect();
      if (!outerCanvasContainer || !this.canvas || !rect.x || !rect.y) return;

      const ratio = this.canvas.getWidth() / this.canvas.getHeight();
      const containerWidth = outerCanvasContainer.clientWidth;
      const scale = containerWidth / this.canvas.getWidth();
      const zoom = this.canvas.getZoom() * scale;

      this.canvas.setDimensions({
        width: containerWidth,
        height: containerWidth / ratio,
      });
      this.canvas.setZoom(zoom);
      // this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    },
    log(...args) {
      console.log({ args });
    },
    onCanvasUpdated(e) {
      canvas = e;
      this.canvas = e;
    },
    onObjModified(e) {
      if (!e?.target || !this.carViews || !this.carViews[this.viewId]) return;
      const view = this.carViews[this.viewId];

      const obj = [...view.figures, ...view.backgrounds].find(
        (f) => f.id === e.target.id
      );
      if (!obj) return;
      obj.props = {
        ...obj.props,
        ...propsToModify.reduce((a, c) => ((a[c] = e.target[c]), a), {}),
      };

      this.carIndxDesignApplied = null;

      this.carViews = { ...this.carViews }; // to update in Vuex
    },
    onObjAdded(e) {
      if (
        !this.isFObjAdded ||
        !e?.target ||
        !/^[fig,sticker,text]/.test(e.target.id)
      )
        return;
      this.isFObjAdded = false;
      this.canvas.setActiveObject(e.target);

      this.carIndxDesignApplied = null;
    },
    onObjRemoved() {
      this.carIndxDesignApplied = null;
    },
    onSelected(e) {
      const fObj = e?.selected[0];
      const id = fObj?.id;
      this.fObjSelectedId = id;
      const tb = this.toolbar;
      tb.image.url = "";
      if (/^text/.test(id)) {
        tb.text = [
          "fill",
          "fontFamily",
          "fontSize",
          "fontWeight",
          "fontStyle",
          "text",
          "underline",
          "linethrough",
        ].reduce((a, c) => ((a[c] = fObj[c]), a), {});
      } else if (/^image/.test(id)) {
        tb.image = ["url"].reduce((a, c) => ((a[c] = fObj[c]), a), {});
      } else if (/^fig/.test(id)) {
        tb.figure = ["fill", "stroke"].reduce(
          (a, c) => ((a[c] = fObj[c]), a),
          {}
        );
      } else if (/^sticker/.test(id)) {
        tb.sticker = ["fill"].reduce((a, c) => ((a[c] = fObj[c]), a), {});
      }
    },
    onUnselected() {
      this.fObjSelectedId = null;
      this.toolbar.image.url = "";
    },
    setCarViews() {
      if (!this?.carViews && this.tempCars) {
        this.carViews = clone(this.tempCars[this.vcq.variant.tempCar_id]);
      }
    },
    sortCarViewFObjs() {
      const fabricObjs = this.canvas.getObjects();
      const indxs = this.fObjIndx;
      fabricObjs
        .sort((a, b) => indxs[a.id] - indxs[b.id])
        .forEach((o) => this.canvas.moveTo(o, indxs[o.id]));
    },
    onMouseOver() {
      this.shownPipette = this.bgGradientPointIndx !== null;
    },
    onMouseMove(e) {
      if (!e || this.bgGradientPointIndx === null) return;
      //const { x, y } = e.pointer;
      const x = e.e.offsetX;
      const y = e.e.offsetY;
      this.coordsPipette = {
        x: x + 50,
        y: y - 30,
      };
      const context2d = this.canvas.lowerCanvasEl.getContext("2d");
      const p = context2d.getImageData(x, y, 1, 1).data;
      const rgba = {
        red: p[0],
        green: p[1],
        blue: p[2],
        alpha: p[3],
      };
      const hexa = rgbaToHexa(rgba, true);
      this.colorPipette = hexa;
    },
    onMouseOut() {
      this.shownPipette = false;
    },
    onMouseUp() {
      const colorStop = this.carViews[this.viewId].backgrounds
        ?.slice()[0]
        ?.children?.slice()[0].props.colorStops[this.bgGradientPointIndx];
      if (colorStop) {
        colorStop.color = this.colorPipette;
        this.carViews = { ...this.carViews };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.invisible {
  opacity: 0;
}
.car-canvas {
  position: relative;
  flex: 1;
}

.pipette {
  position: absolute;
  left: 50px;
  top: -30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 8px 0px;
  z-index: 95;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
