<script>
import fabricObject from "./fabricObject";
export default {
  name: "FabricTextBox",
  mixins: [fabricObject],
  props: {
    top: {
      type: Number,
      default: 40,
    },
    left: {
      type: Number,
      default: 0,
    },
    fontFamily: {
      type: String,
      default: "Times New Roman",
    },
    fontSize: {
      type: Number,
      default: 40,
    },
    fontStyle: {
      type: String,
      default: "normal",
    },
    fontWeight: {
      type: [Number, String],
      default: "normal",
    },
    text: {
      type: String,
      required: true,
    },
    textAlign: {
      type: String,
      default: "left",
    },
    textBackgroundColor: {
      type: String,
      default: "",
    },
    underline: {
      type: Boolean,
      default: false,
    },
    linethrough: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textObj: null,
      type: "text",
    };
  },
  watch: {
    parentItem: {
      handler(newValue) {
        if (newValue) {
          //Parent is created
          this.textObj = new this.fabric.Textbox(this.text, {
            ...this.definedProps,
          });
          if (this.parentType == "group") {
            if (this.parentItem.addWithoutUpdate) {
              this.parentItem.add(this.textObj);
            } else {
              this.parentItem.addWithUpdate(this.textObj);
            }
          } else {
            this.canvas.add(this.textObj);
          }
          this.createEvents();
          this.createWatchers();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {},
  methods: {},
  render(h) {
    return this.$slots.default ? h("div", this.$slots.default) : undefined;
  },
};
</script>
