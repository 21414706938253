<template>
  <div class="top">
    <a v-if="prev && prev.path" :href="prev.path" class="btn_radius prev">
      <i class="far fa-chevron-left"></i> {{ prev.text }}
    </a>
    <router-link
      v-else-if="prev && prev.name"
      :to="{ name: prev.name }"
      class="btn_radius prev"
    >
      <i class="far fa-chevron-left"></i> {{ prev.text }}
    </router-link>
    <Myself :state="state" :total="3" />
  </div>
</template>

<script>
import Myself from "@/components/myself";

export default {
  components: { Myself },
  props: {
    prev: {
      type: Object,
      default: null,
    },
    state: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
