<template>
  <div class="myself">
    <div class="stages">
      <span
        v-for="i in total"
        :key="i"
        :class="['stage', { active: i == state.value }]"
      />
    </div>
    <div class="text">
      <div id="counter" class="counter">
        <span>{{ state.value }}</span>
        / {{ total }}
      </div>
      {{ state.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 3,
    },
  },
};
</script>
