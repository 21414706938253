import { hexaNormalize } from "./index";

export default function hexaToRgba(val) {
  const hexa = hexaNormalize(val);
  const rgba = ["red", "green", "blue", "alpha"].reduce(
    (a, c, i) => ({ ...a, [c]: parseInt(hexa.substr(i * 2 + 1, 2), 16) }),
    {}
  );
  rgba.alpha = rgba.alpha / 255;
  return rgba;
}
