<script>
import fabricObject from "./fabricObject";

export default {
  name: "FabricSvgFromURLSimple",
  mixins: [fabricObject],
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      svg: null,
      type: "svg",
      customWatch: ["url"],
    };
  },
  computed: {
    canvas() {
      return this.$canvas();
    },
  },
  watch: {
    parentItem: {
      handler(val) {
        if (val) {
          this.createSVG();
          this.createEvents();
          this.createWatchers();
        }
      },
      immediate: true,
    },
    url() {
      if (this.parentItem) {
        this.createSVG();
      }
    },
    fill() {
      if (this.parentItem) {
        if (this.svg) {
          this.updateSVG();
        }
      }
    },
  },
  methods: {
    updateSVG() {
      // 2 - update on change group fill
      this.svg.getObjects().map((o) => o.set({ fill: this.fill }));
    },
    async createSVG() {
      this.$emit("url-loading");
      if (this.svg) {
        this.canvas.remove(this.svg);
        this.svg = null;
      }
      // 1 - set group fill
      const group = new this.fabric.Group([], { id: this.id, fill: this.fill });
      this.canvas.add(group);
      this.svg = group;
      this.fabric.loadSVGFromURL(this.url, (objects) => {
        objects.forEach((o) => group.add(o.set({ fill: this.fill })));
        group.addWithUpdate();
        // 3 - restore props
        group.set({ ...this.definedProps }).setCoords();
        this.canvas.renderAll();
        this.$emit("url-loaded");
      });
    },
  },
  render() {
    return null;
  },
};
</script>
