<script>
import fabricObject from "./fabricObject";
export default {
  name: "FabricImageFromURL",
  mixins: [fabricObject],
  props: {
    id: {
      type: String,
      default: "id",
    },
    url: { type: String, default: "../vue.png" },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      image: null,
      type: "image",
      customWatch: ["url"],
    };
  },
  watch: {
    parentItem: {
      handler(newValue) {
        if (newValue) {
          //Parent is created
          this.createImage();
        }
      },
      immediate: true,
    },
    url() {
      if (this.parentItem) {
        if (this.image) {
          this.destroyImage();
        }
        this.createImage();
      }
    },
  },
  methods: {
    createImage() {
      this.$emit("url-loading");
      this.fabric.Image.fromURL(
        this.url,
        (img) => {
          this.image = img;
          this.$emit("image-loaded", img);
          if (this.parentType == "group") {
            this.parentItem.addWithUpdate(this.image);
          } else {
            if (this.id === "bg-image") {
              this.image.scaleX = (this.canvas.width + 50) / img.width;
              this.image.scaleY = (this.canvas.height - 10) / img.height;
            } else if (this.id.indexOf("image-") === 0) {
              this.image.scaleX = (this.canvas.width - 400) / img.width;
              this.image.scaleY = (this.canvas.height - 200) / img.height;
            }
            this.canvas.add(this.image);
            this.image.viewportCenter();
          }
          this.createEvents();
          this.createWatchers();
          this.$emit("url-loaded");
        },
        { ...this.definedProps }
      );
    },
    destroyImage() {
      this.destroyEvents();
      if (this.parentType == "group") {
        if (this.group) {
          this.group.removeWithUpdate(this.image);
        }
      } else {
        if (this.canvas) {
          this.canvas.remove(this.image);
        }
        this.image = null;
      }
    },
  },
  render(h) {
    return this.$slots.default ? h("div", this.$slots.default) : undefined;
  },
};
</script>
